var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    _vm._g({ tag: "component" }, _vm.$listeners),
    [
      _c("b-avatar", {
        staticClass: "badge-minimal",
        attrs: {
          size: "42",
          src: _vm.user.avatar,
          badge: _vm.isChatContact,
          "badge-variant": _vm.resolveAvatarBadgeVariant(_vm.user.status),
          variant: "transparent",
        },
      }),
      _c("div", { staticClass: "chat-info flex-grow-1" }, [
        _c("h5", { staticClass: "mb-0" }, [
          _vm._v(" " + _vm._s(_vm.user.fullName) + " "),
        ]),
        _c("p", { staticClass: "card-text text-truncate" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.isChatContact
                  ? _vm.user.chat.lastMessage.message
                  : _vm.user.about
              ) +
              " "
          ),
        ]),
      ]),
      _vm.isChatContact
        ? _c(
            "div",
            { staticClass: "chat-meta text-nowrap" },
            [
              _c("small", { staticClass: "float-right mb-25 chat-time" }, [
                _vm._v(
                  _vm._s(
                    _vm.formatDateToMonthShort(_vm.user.chat.lastMessage.time, {
                      hour: "numeric",
                      minute: "numeric",
                    })
                  )
                ),
              ]),
              _vm.user.chat.unseenMsgs
                ? _c(
                    "b-badge",
                    {
                      staticClass: "float-right",
                      attrs: { pill: "", variant: "primary" },
                    },
                    [_vm._v(" " + _vm._s(_vm.user.chat.unseenMsgs) + " ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }