var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar-left" }, [
    _c(
      "div",
      { staticClass: "sidebar" },
      [
        _c("user-profile-sidebar", {
          attrs: {
            "shall-show-user-profile-sidebar": _vm.shallShowUserProfileSidebar,
            "profile-user-data": _vm.profileUserData,
          },
          on: {
            "close-sidebar": function ($event) {
              return _vm.$emit("update:shall-show-user-profile-sidebar", false)
            },
          },
        }),
        _c(
          "div",
          {
            staticClass: "sidebar-content",
            class: { show: _vm.mqShallShowLeftSidebar },
          },
          [
            _c(
              "span",
              { staticClass: "sidebar-close-icon" },
              [
                _c("feather-icon", {
                  attrs: { icon: "XIcon", size: "16" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit(
                        "update:mq-shall-show-left-sidebar",
                        false
                      )
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "chat-fixed-search" }, [
              _c(
                "div",
                { staticClass: "d-flex align-items-center w-100" },
                [
                  _c(
                    "div",
                    { staticClass: "sidebar-profile-toggle" },
                    [
                      _c("b-avatar", {
                        staticClass:
                          "cursor-pointer badge-minimal avatar-border-2",
                        attrs: {
                          size: "42",
                          src: _vm.profileUserMinimalData.avatar,
                          variant: "transparent",
                          badge: "",
                          "badge-variant": "success",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.$emit("show-user-profile")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-input-group",
                    { staticClass: "input-group-merge ml-1 w-100 round" },
                    [
                      _c(
                        "b-input-group-prepend",
                        { attrs: { "is-text": "" } },
                        [
                          _c("feather-icon", {
                            staticClass: "text-muted",
                            attrs: { icon: "SearchIcon" },
                          }),
                        ],
                        1
                      ),
                      _c("b-form-input", {
                        staticClass: "pl-1",
                        attrs: { placeholder: "Pesquisar chat..." },
                        model: {
                          value: _vm.searchQuery,
                          callback: function ($$v) {
                            _vm.searchQuery = $$v
                          },
                          expression: "searchQuery",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c(
              "vue-perfect-scrollbar",
              {
                staticClass: "chat-user-list-wrapper list-group scroll-area",
                attrs: { settings: _vm.perfectScrollbarSettings },
              },
              [
                _c("h4", { staticClass: "chat-list-title" }, [
                  _vm._v(" Chats "),
                ]),
                _c(
                  "ul",
                  { staticClass: "chat-users-list contact-list media-list" },
                  _vm._l(_vm.filteredContacts, function (contact) {
                    return _c("chat-contact", {
                      key: contact.id,
                      attrs: { user: contact, tag: "li" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("open-chat", contact.id)
                        },
                      },
                    })
                  }),
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }